<template>
    <section id="filtering-area">
        <div class="select-programm-area">
            <div class="d-flex flex-column flex-sm-row align-items-sm-center selects-container" v-if="!watchDemo">
                <div class="container-text-and-popover">
                    <div>
                        <h3>Select company</h3>
                    </div>
                    <!-- <div classhref="#" tabindex="0" v-show="watchDemo" class="popover-style"></div> -->
                </div>
                <div>
                    <v-select v-model="localCompanySelected" :options="localCompanyList" :clearable="false"
                        :loading="loadingCompanies" :disabled="watchDemo" class="select-companies"
                        @input="updateSelectedCompany">
                    </v-select>
                </div>
                <div>
                    <div class="container-text-and-popover">
                        <div>
                            <h3>Select project</h3>
                        </div>
                        <div classhref="#" tabindex="0" v-show="watchDemo" class="popover-style">
                            <Bpopover2 :info="info2" idPopover="2PB" id="2pbC" />
                        </div>
                    </div>
                </div>

                <column :lg="3" :xs="2">
                    <v-select v-model="localProjectSelected" :options="localProjectList" :clearable="false"
                        :loading="loadingProjects" :disabled="projectSelectedDisabled || watchDemo"
                        @input="updateSelectedProject" class="select-projects">
                    </v-select>
                </column>
            </div>


            <div class="alignDashboards">
                <i class="iconCursor" v-on:click="SelectToOrder(false)" v-show="changeDisplay == true">
                    <BIconGrid3x3Gap class="icon-styled" />
                </i>
                <i v-show="changeDisplay == false">
                    <BIconGrid3x3GapFill class="icon-styled" />
                </i>
                <div class="listIcon" v-on:click="SelectToOrder(true)" :style="changeDisplay == true && 'cursor: default;'">
                    <span v-for="index in 3" :key="index" class="spanLine" :class="changeDisplay && 'bg-purple'"></span>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Bpopover2 from "../B-popover.vue";
import { BIconGrid3x3Gap, BIconGrid3x3GapFill } from "bootstrap-vue";

export default {
    name: 'FilteringSelector',
    components: {
        Bpopover2,
        BIconGrid3x3Gap,
        BIconGrid3x3GapFill,
    },
    data() {
        return {
            info2: `If you run more than one programmes as 
              a fund, foundation, charity or sustainable 
              corporation, you can navigate through the 
              dashboards of each programme, from this 
              drop down selection.`,
            localCompanySelected: this.companySelected,
            localProjectSelected: this.projectSelected,
            localCompanyList: this.companyList,
            localProjectList: this.projectList,
        }
    },
    props: {
        watchDemo: {
            type: Boolean,
        },
        companyList: {
            type: Array,
        },
        changeDisplay: {
            type: Boolean,
        },
        loadingCompanies: {
            type: Boolean,
        },
        SelectToOrder: {
            type: Function,
        },
        companySelected: {
            type: Object,
            default: () => ({ label: "", value: null, archived: false }),
        },
        projectSelected: {
            type: Object,
            default: () => ({ label: "", value: null, archived: false }),
        },
        projectList: {
            type: Array,
        },
        loadingProjects: {
            type: Boolean,
        },
        projectSelectedDisabled: {
            type: Boolean,
        },
    },
    watch: {
        companySelected(newValue) {
            this.localCompanySelected = newValue;
        },
        projectSelected(newValue) {
            this.localProjectSelected = newValue;
        },
        companyList(newValue) {
            this.localCompanyList = newValue;
        },
        projectList(newValue) {
            this.localProjectList = newValue;
        }
    },
    methods: {
        updateSelectedCompany() {
            this.$emit("update-selected-company", this.localCompanySelected);
        },
        updateSelectedProject() {
            this.$emit("update-selected-project", this.localProjectSelected);
        },
    }
}
</script>

<style scoped>
.selects-container {
    gap: 1rem;
}
</style>