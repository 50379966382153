<template>
  <div>
    <svg class="progress-ring" :width="circleSize" :height="circleSize">
      <circle
        class="progress-ring__circle"
        :stroke="strokeColor"
        :stroke-width="strokeWidth"
        fill="transparent"
        :r="radius"
        :cx="centerX"
        :cy="centerY"
        :stroke-dashoffset="strokeDashoffset"
        :stroke-dasharray="strokeDasharray"
      />
      <text
        v-if="showText"
        class="progress-text"
        :x="centerX"
        :y="centerY"
        text-anchor="middle"
        dominant-baseline="middle"
        :style="{ fontSize: textSize + 'px' }"
      >
        {{progress}}
      </text>
    </svg>
  </div>
</template>

<script>
export default {
  name: "ImpactProgress",
  props: {
    progress: {
      type: Number,
      required: true,
    },
    circleSize: {
      type: Number,
      default: 120,
    },
    showText: {
      type: Boolean,
      default: true,
    },
    textSize: {
      type: Number,
      default: 24,
    },
    strokeWidth: {
      type: Number,
      default: 4,
    },
    strokeColor: {
      type: String,
      default: "white",
    },
  },
  data() {
    return {
      strokeDashoffset: 0,
      strokeDasharray: "",
    };
  },
  computed: {
    radius() {
      return (this.circleSize - this.strokeWidth) / 2;
    },
    centerX() {
      return this.circleSize / 2;
    },
    centerY() {
      return this.circleSize / 2;
    },
  },
  watch: {
    progress(newVal) {
      if (newVal >= 0 && newVal <= 100) {
        this.setProgress(newVal);
      }
    },
  },
  mounted() {
    if (this.progress >= 0 && this.progress <= 100) {
      this.setProgress(this.progress);
    }
  },
  methods: {
    setProgress(percent) {
      const circumference = this.radius * 2 * Math.PI;
      const offset = circumference - (percent / 100) * circumference;
      this.strokeDashoffset = offset;
      this.strokeDasharray = `${circumference} ${circumference}`;
    },
  },
};
</script>

<style scoped>
.progress-ring__circle {
  transition: 0.35s stroke-dashoffset;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}

.progress-text {
  fill: #8954ba;
  font-weight: bold;
}
</style>
