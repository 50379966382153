<template>
    <row :gutter="12">
        <router-link to="/ins">
            <column class="thumbnail-tile" :lg="4">
                <div class="link-title">
                  <h1>INS Lessons</h1>
                  <h1 class="color-purple">{{insGrowthRate}}</h1>
                </div>
                <img src="../../../src/assets/dummy1.svg"/>
                <h3>Showing data up to {{lastUpdate}}</h3>
            </column>
        </router-link>
        <router-link to="/attendance">
            <column class="thumbnail-tile" :lg="4">
                <div class="link-title">
                <h1>Students attendance</h1>
                <h1 class="color-purple"></h1>
                </div>
                <img src="../../../src/assets/dummy2.svg"/>
                <h3>Showing data up to {{lastUpdate}}</h3>
            </column>
        </router-link>
        <router-link to="/ict">
            <column class="thumbnail-tile" :lg="4">
                <div class="link-title">
                <h1>ICT skills acquired</h1>
                <h1 class="color-purple">{{ictGrowthRate}}</h1>
                </div>
                <img src="@/assets/components/projectDetails/dummy3.svg"/>
                <h3>Showing data up to {{lastUpdate}}</h3>
            </column>
        </router-link>
    </row>
</template>

<script>
import { getLessons, getStudentAvgAcrossSchools } from '../data/data-provider.js'
import { compareDataByYear, calcDifference } from '../data/data-handler'

export default {

  name: 'home',
  components: {
    // LinkPrevue
  },
  data () {
    return {
      dashboardName: 'INS Lessons',
      lastUpdate: '31/12/2019',
      insGrowthRate: '',
      ictGrowthRate: ''
    }
  },
  mounted () {
    this.getInsGrowthRate()
    this.showNavBar()
  },
  methods: {
    getInsGrowthRate () {
      const prevYear = getLessons([], [], [], '2018')
      const currYear = getLessons([], [], [], '2019')
      this.insGrowthRate = compareDataByYear(Object.values(prevYear.lessons[0]), Object.values(currYear.lessons[0]))
      this.ictGrowthRate = calcDifference([getStudentAvgAcrossSchools('Total', 'Base')], [getStudentAvgAcrossSchools('Total', 'End')])[0]
    },
    showNavBar () {
      const navbar = document.getElementById('nav')
      navbar.style.display = 'inline'
    }
  }
}
</script>

<style scoped>

router-link {
  text-decoration: none!important;
}

.color-purple {
  color: var(--color-purple)
}

</style>