<template>
  <div class="home">
    <main id="home">
      <FilteringSelector :watchDemo="watchDemo" :companyList="companyList"
        @update-selected-company="updateSelectedCompany" @update-selected-project="updateSelectedProject"
        :changeDisplay="changeDisplay" :loadingCompanies="loadingCompanies" :SelectToOrder="SelectToOrder"
        :companySelected="companySelected" :projectSelected="projectSelected" :projectList="projectList"
        :loadingProjects="loadingProjects" :projectSelectedDisabled="projectSelectedDisabled" />

      <section id="thumbnail-area">
        <row :gutter="12" class="align-items-center banner-title-container">
          <div :gutter="6">
            <h1 class="impact-work">See the impact of your work</h1>
          </div>

          <div :gutter="6" class="container-styled">
            <h2 class="you-profile-title" v-bind:class="{ inActivedemo: watchDemo }" v-on:click="activateDemo(false)">
              Your view
            </h2>

            <!-- Gray bar -->
            <div class="views-divisor"></div>

            <div :lg="12" class="container-text-and-popover">
              <div>
                <h2 :lg="12" :xs="12" class="container-text-and-popover-title"
                  v-bind:class="{ activedemopt20: watchDemo }" v-on:click="activateDemo(true)">
                  Demo view
                </h2>
              </div>
              <div v-show="watchDemo" classhref="#" tabindex="0" class="popover-style">
                <Bpopover1 :info="info1" idPopover="pb1" />
              </div>
            </div>
          </div>
        </row>

        <Vodafone v-if="watchDemo == false && vodafoneDashboard == true" />


        <Impacts :changeDisplay="changeDisplay" :preloader="loadingImpacts" :watchDemo="watchDemo"
          :impactList="impactListFiltered" />
      </section>
    </main>
    <PopUpCardModal ref="modalInfoMessage" :isPopupActive="true" :closePopUp="closePopUp" :titleCard="titleCard"
      :textMsg="modalMessage" :isOk="true" />
  </div>
</template>

<script>
import { getCompaniesByGroup } from "../services/company";
import { getProjectsByCompanyId } from "../services/project";
import {
  getImpactByProjectId,
  getImpactByCompanyId,
  getImpactByGroupId,
  getImpactsDemo,
} from "../services/impact";
import Bpopover1 from "../components/B-popover.vue";
import Impacts from "../components/Impacts.vue";
import { store } from "../store/store";
import Vodafone from "@/modules/components/Vodafone";
import { socketStateBackground } from "../services/socket_background.js";
import PopUpCardModal from "../components/shared/PopUpCardModal.vue";
import FilteringSelector from "../components/Home/FilteringSelector.vue";

export default {
  name: "Home",
  beforeRouteEnter(to, from, next) {
    if (from.name === 'editbenchmetric') {
      next(vm => {
        if (from.name === 'editbenchmetric') {
          const { createDashboard, company, project } = to.params;

          if (createDashboard === true && company && project) {

            vm.$cookies.set('lastSelectedOptionCompanyHome', company);
            vm.$cookies.set('lastSelectedOptionProjectHome', project);

            // show popup
            vm.$nextTick(() => {
              vm.$refs.modalInfoMessage.showModal();
            });
          }
        }
      });
    }
    next();
  },
  components: {
    Bpopover1,
    Impacts,
    Vodafone,
    PopUpCardModal,
    FilteringSelector
  },
  data() {
    return {
      watchDemo: true,
      search: "",
      vodafoneDashboard: false,
      preloader: false,
      info1: `This is our demo dashboard, with ‘People 
              in employment’ and ’Skills acquired’ 
              representing 2 types of data visualisation 
              and ’SDG benchmark’s showing an 
              example ’ of SDG measurement.`,
      changeDisplay: false,
      loadingCompanies: false,
      loadingProjects: false,
      loadingImpacts: false,
      companyList: [],
      projectList: [],
      companySelected: { label: "", value: null, archived: false },
      projectSelected: { label: "", value: null, archived: false },
      impactList: [],
      projectSelectedDisabled: false,
      titleCard: 'Dashboard in creation',
      modalMessage: 'Your dashboard is being created. It should take approximately 1 minute. You will see a progress circle on your dashboard thumbnail here. Once the dashboard is created a tick will appear.',
    };
  },
  mounted() {
    this.$root.$on("mainSearch", (text) => {
      this.search = text;
    });
    this.preloader = true;
    this.showNavBar();
    this.loadCompanies();
    this.watchDemo = store.state.demoView;
  },
  watch: {
    finish_impact_cache() {
      if (this.finish_impact_cache.length > 0) {
        this.updatePercentageImpact();
      }
    },
    companySelected() {
      if (this.companySelected?.value) {
        this.loadProjects();
        this.projectSelectedDisabled = false;
      } else {
        this.projectSelectedDisabled = true;
      }
    },
    projectSelected() {
      this.loadImpacts();
    },
    watchDemo(newValue) {
      if (newValue) return this.loadImpacts();
      if (this.$cookies.isKey('lastSelectedOptionCompanyHome')) {
        const lastSelectedOptionCompanyHome = this.$cookies.get('lastSelectedOptionCompanyHome');
        const companyExistsInList = this.companyList.some(company => company.value === lastSelectedOptionCompanyHome.value);
        if (companyExistsInList) this.companySelected = lastSelectedOptionCompanyHome;
      }
    }
  },
  computed: {
    finish_impact_cache() {
      return socketStateBackground.finish_impact_cache_events;
    },
    impactListFiltered() {
      try {
        if (!this.search) {
          return this.impactList;
        }
        const targetName = this.removeAccents(this.search).toLowerCase();
        return this.impactList.filter((item) => {
          const objName = this.removeAccents(item.Title).toLowerCase();
          return objName.includes(targetName);
        });
      } catch (error) {
        return this.impactList;
      }
    },
  },
  methods: {
    updateSelectedCompany(companySelected) {
      if (companySelected) {
        this.$cookies.set('lastSelectedOptionCompanyHome', companySelected);
        this.companySelected = companySelected;
      }
    },
    updateSelectedProject(projectSelected) {
      if (!projectSelected) return;
      this.$cookies.set('lastSelectedOptionProjectHome', projectSelected);
      this.projectSelected = projectSelected;
    },
    closePopUp() {
      this.$refs.modalInfoMessage.hide();
    },
    removeAccents(str) {
      return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    },
    SelectToOrder(info) {
      this.changeDisplay = info;
    },
    activateDemo(watchDemo) {
      this.preloader = true;
      this.watchDemo = watchDemo;
      store.commit("setDemoView", watchDemo);
      // this.companySelected = { label: "", value: null, archived: false };
      // this.projectSelected = { label: "", value: null, archived: false };
    },
    showNavBar() {
      const navbar = document.getElementById("nav");
      navbar.style.display = "inline";
      const sidebar = document.getElementById("sidebar");
      sidebar.style.display = "block";
    },
    async loadCompanies() {
      try {
        this.loadingCompanies = true;
        const res_data = await getCompaniesByGroup({
          archived: false,
          detailed: false,
        });
        if (res_data.error === false) {
          const list_aux = res_data.companies.map((x) => {
            return { label: x.name, value: x._id, archived: x.archived };
          });

          this.companyList = list_aux.reverse();

          if (this.$cookies.isKey('lastSelectedOptionCompanyHome')) {
            // Check if a company has already been selected in the cookies
            const lastSelectedOptionCompanyHome = this.$cookies.get('lastSelectedOptionCompanyHome');
            // Check if the "value" of lastSelectedOptionCompanyHome exists in this.companyList
            const companyExistsInList = this.companyList.some(company => company.value === lastSelectedOptionCompanyHome.value);

            if (companyExistsInList) {
              // The company in lastSelectedOptionCompanyHome still exists in this.companyList, so we assign it to companySelected
              this.companySelected = lastSelectedOptionCompanyHome;
              return;
            } else {
              // If the last selected company doesn't exist in the cookies, then we assign the last created company
              this.companySelected = list_aux[0];
              this.$cookies.set('lastSelectedOptionCompanyHome', this.companySelected);
              return;
            }
          }

          // Si solo existe una company, entonces la colocamos en las cookies por defecto
          if (list_aux.length === 1) {
            this.$cookies.set('lastSelectedOptionCompanyHome', this.companyList[0]);
          }

          // Si existe mas de una company
          if (list_aux && list_aux.length > 0) {
            // when you log out and log back in, the last option created is selected by default
            if (JSON.stringify(this.companySelected) === "{}" || (
              (this.companySelected.archived === null || this.companySelected.archived === false) &&
              this.companySelected.label === '' &&
              this.companySelected.value === null)
            ) {
              // Colocamos en las cookies la ultima company creada
              this.companySelected = list_aux[0];
              this.$cookies.set('lastSelectedOptionCompanyHome', this.companySelected);
            }
          }

        } else {
          this.companyList = [];
        }
      } catch (error) {
        this.companyList = [];
      } finally {
        this.loadingCompanies = false;
      }
    },
    async loadProjects() {
      try {
        this.loadingProjects = true;
        const res_data = await getProjectsByCompanyId(
          this.companySelected.value,
          {
            archived: false,
          }
        );
        if (res_data.error === false) {
          const list_aux = res_data.projects.map((x) => {
            return { label: x.name, value: x._id, archived: x.archived };
          });

          list_aux.reverse();
          this.projectList = list_aux;

          if (this.$cookies.isKey('lastSelectedOptionProjectHome')) {
            const lastSelectedOptionProjectHome = this.$cookies.get('lastSelectedOptionProjectHome');
            const projectExistsInList = this.projectList.some(project => project.value === lastSelectedOptionProjectHome.value);
            if (projectExistsInList) {
              this.projectSelected = lastSelectedOptionProjectHome;
              return;
            } else {
              this.projectSelected = list_aux[0];
              this.$cookies.set('lastSelectedOptionProjectHome', this.projectSelected);
              return;
            }
          }

          if (list_aux.length === 1) {
            this.projectSelected = list_aux[0];
            this.$cookies.set('lastSelectedOptionProjectHome', this.projectSelected);
          }

          if (list_aux && list_aux.length > 0) {
            if (JSON.stringify(this.projectSelected) === "{}" || (
              (this.projectSelected.archived === null || this.projectSelected.archived === false) &&
              this.projectSelected.label === '' &&
              this.projectSelected.value === null)
            ) {
              this.projectSelected = list_aux[0];
              this.$cookies.set('lastSelectedOptionProjectHome', this.projectSelected);
            }
          }

        } else {
          this.projectList = [];
        }
      } catch (error) {
        this.projectList = [];
      } finally {
        this.loadingProjects = false;
      }
    },
    async loadImpacts() {
      try {
        this.loadingImpacts = true;
        let res_data;
        if (this.watchDemo) {
          res_data = await getImpactsDemo();
        } else if (!this.companySelected?.value) {
          res_data = await getImpactByGroupId({
            archived: false,
          });
        } else if (this.projectSelected.value == null) {
          res_data = await getImpactByCompanyId(this.companySelected.value, {
            archived: false,
          });
        } else {
          res_data = await getImpactByProjectId(this.projectSelected.value, {
            archived: false,
          });
        }
        if (res_data.error === false) {
          this.impactList = res_data.impacts;
        } else {
          this.impactList = [];
        }
      } catch (error) {
        this.impactList = [];
      } finally {
        this.loadingImpacts = false;
      }
    },
    updatePercentageImpact() {
      if (this.loadingImpacts === true) {
        return;
      }
      const data = socketStateBackground.finish_impact_cache_events.shift();
      const new_impact_percentage = this.impactList.findIndex((item) => item._id == data.idImpact);
      if (new_impact_percentage !== -1) {
        this.impactList[new_impact_percentage].cache_percentage = data.percentage;
        this.impactList[new_impact_percentage].cached = data.cached;
      } else {
        if (this.companySelected.value == null && !this.companySelected?.value) {
          this.loadImpacts();
        } else if (this.companySelected.value == data.companyId) {
          if (this.projectSelected.value == null || this.projectSelected.value == data.idProject) {
            this.loadImpacts();
          }
        }
      }
    },
  },
};
</script>
<style scoped>
@import "../assets/styles/views/Home.css";
</style>
