<template>
  <div>
    <transition name="fade">
      <row v-if="changeDisplay == false" :gutter="12" id="thumbnails">
        <row :gutter="12" v-if="preloader">
          <column class="thumbnail-tile" :lg="3.5">
            <div class="link-title">
              <h1 class="loader-animation">&nbsp;.</h1>
              <h1 class="loader-animation">&nbsp;</h1>
            </div>
            <img class="loader-animation" />
            <h3 class="loader-animation">`</h3>
          </column>
          <column class="thumbnail-tile" :lg="3.5">
            <div class="link-title">
              <h1 class="loader-animation">&nbsp;.</h1>
              <h1 class="loader-animation">&nbsp;</h1>
            </div>
            <img class="loader-animation" />
            <h3 class="loader-animation">`</h3>
          </column>
          <column class="thumbnail-tile" :lg="3.5">
            <div class="link-title">
              <h1 class="loader-animation">&nbsp;.</h1>
              <h1 class="loader-animation">&nbsp;</h1>
            </div>
            <img class="loader-animation" />
            <h3 class="loader-animation">`</h3>
          </column>
        </row>
        <template v-if="!preloader">
          <div v-for="(impact, index) in reversedImpactList" v-bind:key="index">
            <div
              v-if="
                impact._id != '666cbb666e66bb6e6c2b66e6' &&
                impact._id != '888cbb888e88bb8e8c8b88e8'
              "
            >
              <router-link
                :to="`/projectdetails/${impact._id}`"
                v-if="impact.Title.length"
              >
                <column class="thumbnail-tile" :lg="4">
                  <div class="link-title">
                    <h1 class="color-gray-text">
                      {{ impact.Title }}
                    </h1>
                    <img
                      v-if="impact.cached"
                      src="@/assets/home/purple-check.svg"
                      class="loading-gif"
                      alt="This dashboard is correctly cached"
                    />
                    <ImpactProgress v-else
                      strokeColor="#8954ba"
                      :progress="impact.cache_percentage"
                      :strokeWidth="4"
                      :circleSize="26"
                      :textSize="8"
                    />
                  </div>
                  <img :src="impact.img" />
                  <h3>
                    Showing data up to {{ impact.createdAt.slice(0, 10) }}
                  </h3>
                </column>
              </router-link>
            </div>
            <div v-if="impact._id == '888cbb888e88bb8e8c8b88e8'">
              <router-link :to="`/frameworktem`" v-if="impact.Title.length">
                <column class="thumbnail-tile" :lg="4">
                  <div class="link-title">
                    <h1 class="color-gray-text">{{ impact.Title }}</h1>
                    <img
                      src="@/assets/home/purple-check.svg"
                      class="loading-gif"
                      alt="This dashboard is correctly cached"
                    />
                  </div>
                  <img :src="impact.img" />
                  <h3>
                    Showing data up to {{ impact.createdAt.slice(0, 10) }}
                  </h3>
                </column>
              </router-link>
            </div>
            <column v-if="impact._id == '666cbb666e66bb6e6c2b66e6'">
              <router-link :to="`/ict`" v-if="impact.Title.length">
                <column class="thumbnail-tile" :lg="12">
                  <div class="link-title">
                    <h1 class="color-gray-text">{{ impact.Title }}</h1>
                  </div>
                  <img :src="impact.img" />
                  <h3>
                    Showing data up to {{ impact.createdAt.slice(0, 10) }}
                  </h3>
                </column>
              </router-link>
            </column>
          </div>
        </template>
        <h1 class="no-container" v-if="!impactList.length">
          Please tap on the third icon on the menu bar to create your project
        </h1>
      </row>
    </transition>
    <transition name="fade">
      <row
        v-if="changeDisplay == true"
        class="display-table"
        :gutter="12"
        id="thumbnails"
      >
        <row
          :gutter="12"
          v-if="preloader"
          class="d-flex flex-column"
        >
          <column
            class="thumbnail-tile-column"
            :lg="3.5"
          >
            <img class="loader-animation-column" />
            <h3 class="loader-animation-column">`</h3>
            <h1 class="loader-animation-column">&nbsp;.</h1>
            <h1 class="loader-animation-column">&nbsp;</h1>
          </column>
          <column
            class="thumbnail-tile-column"
            :lg="3.5"
          >
            <img class="loader-animation-column" />
            <h3 class="loader-animation-column">`</h3>
            <h1 class="loader-animation-column">&nbsp;.</h1>
            <h1 class="loader-animation-column">&nbsp;</h1>
          </column>
          <column
            class="thumbnail-tile-column"
            :lg="3.5"
          >
            <img class="loader-animation-column" />
            <h3 class="loader-animation-column">`</h3>
            <h1 class="loader-animation-column">&nbsp;.</h1>
            <h1 class="loader-animation-column">&nbsp;</h1>
          </column>
        </row>
        <template v-if="!preloader">
          <div v-for="(impact, index) in impactList" v-bind:key="index">
            <div
              v-if="
                impact._id != '666cbb666e66bb6e6c2b66e6' &&
                impact._id != '888cbb888e88bb8e8c8b88e8'
              "
            >
              <router-link
                :to="`/projectdetails/${impact._id}`"
                v-if="impact.Title.length"
              >
                <div class="thumbnail-tile-column">
                  <img :src="impact.img" />
                  <div class="impactInfo">
                    <span></span>
                    <h1 class="color-gray-text">{{ impact.Title }}</h1>
                    <span></span>
                    <h3>Programme: {{ "project_name" }}</h3>
                    <span></span>
                    <h3>
                      Showing data up to {{ impact.createdAt.slice(0, 10) }}
                    </h3>
                  </div>
                </div>
              </router-link>
            </div>
            <div v-if="impact._id == '888cbb888e88bb8e8c8b88e8'">
              <router-link
                :to="`/frameworktem`"
                v-if="impact.Title.length"
              >
                <div class="thumbnail-tile-column">
                  <img :src="impact.img" />
                  <div class="impactInfo">
                    <span></span>
                    <h1 class="color-gray-text">{{ impact.Title }}</h1>
                    <span></span>
                    <h3>Programme: {{ "project_name" }}</h3>
                    <span></span>
                    <h3>
                      Showing data up to {{ impact.createdAt.slice(0, 10) }}
                    </h3>
                  </div>
                </div>
              </router-link>
            </div>
            <column v-if="impact._id == '666cbb666e66bb6e6c2b66e6'">
              <router-link :to="`/ict`" v-if="impact.Title.length">
                <div class="thumbnail-tile-column">
                  <img :src="impact.img" />
                  <div class="impactInfo">
                    <span></span>
                    <h1 class="color-gray-text">{{ impact.Title }}</h1>
                    <span></span>
                    <h3>Programme: {{ "project_name" }}</h3>
                    <span></span>
                    <h3>
                      Showing data up to {{ impact.createdAt.slice(0, 10) }}
                    </h3>
                  </div>
                </div>
              </router-link>
            </column>
          </div>
        </template>

        <h1 class="no-container" v-if="!impactList.length">
          Please tap on the third icon on the menu bar to create your project
        </h1>
      </row>
    </transition>
    <row :gutter="12" id="thumbnails" class="row-arrow-container">
      <h2 class="activedemo" v-show="watchDemo">
        <img src="../assets/home/arrow-blue.svg" class="arrow-blue-img" />
        All data values in our demo view are fictional
      </h2>
    </row>
  </div>
</template>

<script>
import ImpactProgress from "../components/Home/ImpactProgress.vue";

export default {
  components: {
    ImpactProgress,
  },
  props: {
    preloader: {
      type: Boolean,
    },
    changeDisplay: {
      type: Boolean,
    },
    watchDemo: {
      type: Boolean,
    },
    impactList: {
      type: Array,
    },
  },
  computed: {
    reversedImpactList() {
      return this.impactList.slice().reverse();
    }
  }
};
</script>

<style scoped>
@import "../assets/styles/components/Impacts.css";
</style>
